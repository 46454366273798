import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing translation files

import translationEN from './locales/en/brief-translation.json'
import translationReferEN from './locales/en/refer-translation.json'
import translationMarketplaceEN from './locales/en/marketplace-translation.json'
import translationCampaignEN from './locales/en/campaign-translation.json'
import translationUserEN from './locales/en/user-translation.json'
import translationPartnerEN from './locales/en/partner-translation.json'
import translationInviteClientEN from './locales/en/inviteclient-translation.json'
import translationCommonEN from './locales/en/common.json'
import translationV2EN from './locales/en/v2-translation.json'

import translationAR from './locales/ar/brief-translation.json'
import translationReferAR from './locales/ar/refer-translation.json'
import translationMarketplaceAR from './locales/ar/marketplace-translation.json'
import translationCampaignAR from './locales/ar/campaign-translation.json'
import translationUserAR from './locales/ar/user-translation.json'
import translationPartnerAR from './locales/ar/partner-translation.json'
import translationInviteClientAR from './locales/ar/inviteclient-translation.json'
import translationCommonAR from './locales/ar/common.json'
import translationV2AR from './locales/ar/v2-translation.json'

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
    translationRefer: translationReferEN,
    translationMarketplace: translationMarketplaceEN,
    translationCampaign: translationCampaignEN,
    translationUser: translationUserEN,
    translationPartner: translationPartnerEN,
    translationInviteClient: translationInviteClientEN,
    translationCommon: translationCommonEN,
    translationV2:translationV2EN
  },
  ar: {
    translation: translationAR,
    translationRefer: translationReferAR,
    translationMarketplace: translationMarketplaceAR,
    translationCampaign: translationCampaignAR,
    translationUser: translationUserAR,
    translationPartner: translationPartnerAR,
    translationInviteClient: translationInviteClientAR,
    translationCommon: translationCommonAR,
    translationV2:translationV2AR
  },
}

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', //default language
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
