import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { store, persistor } from "../../../store";
import {
  serverSuccessInterceptor,
  serverErrorInterceptor,
} from "../../../api/serverInterceptor";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { requestHttp } from "../../../api";
import { Provider } from "react-redux";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import "../../../styles/main.scss";
import i18n from "src/i18n";
import { LOCALIZATION_LANGUAGES } from "src/constants";

export default function () {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

  (async () => {
    const { REACT_APP_THEME: theme } = process.env;

    try {
      if (theme === 'NANA') {
        // @ts-ignore
        await import("../../../styles/nanaoverride.scss");
      }

      if (theme === 'MRSOOL') {
        // @ts-ignore
        await import("../../../styles/mrsooloverride.scss");
      }

      if (theme === 'SRMG') {
        // @ts-ignore
        await import("../../../styles/srmgoverride.scss");
      }

    } catch (error) {
      console.log(error.message)
    }
  })();

  const [isLoading, setLoading] = useState(true);

  const fakeRequest = () => {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), 1500));
  };

  const hideLoader = async () => {
    await fakeRequest();
    const el = document.querySelector(".loader");
    if (el) {
      el.remove();
      setLoading(false);
    }
  };

  useEffect(() => {
    hideLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const savedLang = localStorage.getItem("localization")
    if (savedLang) {
      i18n.changeLanguage(savedLang)
    }
  }, [])

  if (isLoading) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        onBeforeLift={undefined}
        persistor={persistor}
      >
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
          <App />
        </GoogleReCaptchaProvider>
        <Helmet>
          <title>{i18n.language === LOCALIZATION_LANGUAGES.ENGLISH ? 'Ad Management Platform' : 'منصة إدارة الإعلانات'}</title>
        </Helmet>
      </PersistGate>
    </Provider>
  );
}

requestHttp.interceptors.response.use(
  serverSuccessInterceptor(),
  serverErrorInterceptor(store)
);
