import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { PATHS } from 'src/constants'
import { Col, Form, QButton, QInput, Row } from 'quantum_components'
import { validateMessages } from '../../../../helpers/validationMessage'
import AuthLayout from '../../../../components/Layouts/AuthLayout'
import { useReduxDispatch } from '../../../../helpers'
import { Store } from 'antd/lib/form/interface'
import { actions } from '../resetPassword.module'
import { useTranslation } from 'react-i18next'

export default function ResetPasswordContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const { t } = useTranslation(['translationUser', 'translationCommon'])

  const submit = ({ email }: Store) => {
    dispatch(actions.resetPassword(email))
  }
  return (
    <AuthLayout AuthPageLadyImageMaxWidth={"400px"}>
      <div className="auth-form">
        <h1>{t("user.reset-password.reset_your_password")}</h1>
        <Form layout="vertical" hideRequiredMark validateMessages={{ ...validateMessages, required: t('required field') }} onFinish={submit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t("user.reset-password.email")}
                name="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    max: 255,
                    type: 'email',
                  },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <QInput placeholder={t("user.reset-password.enter_your_email_address")} type="email" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <QButton className="text-capitalize mt-16" type="primary" block htmlType="submit">
            {t("user.reset-password.send_password_reset_email")}
          </QButton>
        </Form>
      </div>
      <div className="auth-section-footer">
        <p>
          {t("user.reset-password.back_to")} <Link to={PATHS.SIGN_IN}>{t("user.reset-password.sign_in")}</Link>
        </p>
      </div>
    </AuthLayout>
  )
}
