const MODULE_NAME = 'auth'

export const getGenerateOTPUrl = (): string => `${MODULE_NAME}/resend-otp`

export const getVerifyOTPUrl = (): string => `${MODULE_NAME}/verify-otp`

export const getRefreshUrl = (): string => `${MODULE_NAME}/refresh`

export const getLoginUrl = (): string => `${MODULE_NAME}/sign-in`

export const getQuickLoginUrl = (): string => `${MODULE_NAME}/email-sign-in`

export const getSocialLoginUrl = (): string => `${MODULE_NAME}/social-sign-in`

export const getLogoutUrl = (): string => `${MODULE_NAME}/logout`

export const getSignUpUrl = (): string => `${MODULE_NAME}/sign-up`

export const getQuickSignUpUrl = (): string => `${MODULE_NAME}/quick-sign-up`

export const getSocialSignUpUrl = (): string => `${MODULE_NAME}/social-sign-up`

export const getActivateAccountUrl = (): string => `${MODULE_NAME}/activate-account`

export const getResetPasswordUrl = (): string => `${MODULE_NAME}/restore-password`

export const getChangePasswordUrl = (): string => `${MODULE_NAME}/change-password`

export const getCoBrandingDataUrl = (hashId: string): string => `p/${hashId}`

export const getInviterSpecialTokenUrl = (): string => `create-temp-account`

export const getInviterBriefDetailsUrl = (): string => `client/user-checkpoint`

export const getExclusivePartnerUrl = (id: number): string => `partner-data/${id}`

export const getVerifyInviteClientUrl = (hashId: string): string => `verify-invite-link/${hashId}`
