import React, { ReactElement, useState } from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { PATHS, REGEXES } from 'src/constants'
import { Col, Form, QButton, QInput, Row, QButtonEye } from 'quantum_components'
import AuthLayout from '../../../../components/Layouts/AuthLayout'
import { useReduxDispatch } from '../../../../helpers'
import { actions } from '../restorePassword.module'
import { IRestorePasswordField, IRestorePasswordFormFields, IPasswordsTypeState } from '../restorePassword.types'
import VALIDATE_STATUSES from '../../../../constants/validateStatuses'
import clone from 'lodash/clone'
import { VALIDATION_MESSAGE } from '../../../../constants/validationMessages'
import i18n from 'src/i18n'

const InitialValues: IRestorePasswordFormFields = {
  password: { value: '', errorMsg: '', validateStatus: '' } as IRestorePasswordField,
  confirmPassword: { value: '', errorMsg: '', validateStatus: '' } as IRestorePasswordField,
}

export default function RestorePasswordContainer(): ReactElement {
  const { t } = useTranslation(['translationUser', 'translationCommon', 'translation'])
  const [formData, setFormData] = useState<IRestorePasswordFormFields>(InitialValues)

  const [passwordsTypeState, setPasswordsState] = useState<IPasswordsTypeState>({
    password: false,
    confirmPassword: false,
  })

  const dispatch = useReduxDispatch()
  const { token }: any = useParams()

  const tips = t(VALIDATION_MESSAGE.PASSWORD, { ns: "translation" })

  const isCorrectPassword = (password: string) => {
    return password.match(REGEXES.PASSWORD)
  }

  const isCorrectConfirmPassword = (password: string) => {
    return password.length > 0 && password === formData.password.value
  }

  const validatePassword = (fieldName: 'password' | 'confirmPassword', value: string) => {
    const field = {
      value: value,
      validateStatus: '',
      errorMsg: '',
    } as IRestorePasswordField

    const isPassword = fieldName === 'password'
    const isValid = isPassword ? isCorrectPassword(value) : isCorrectConfirmPassword(value)

    if (isValid) {
      field.validateStatus = VALIDATE_STATUSES.SUCCESS
    } else {
      field.validateStatus = VALIDATE_STATUSES.ERROR
      field.errorMsg = isPassword ? tips : t('user.change-password.repeat_validation')
    }

    return field
  }

  const onPasswordChange = (fieldName: 'password' | 'confirmPassword', value: string) => {
    const data = { ...formData }
    data[fieldName] = validatePassword(fieldName, value)
    setFormData(data)
  }

  const validateFormFields = () => {
    const data = { ...formData }
    data.password = validatePassword('password', data.password.value)
    data.confirmPassword = validatePassword('confirmPassword', data.confirmPassword.value)
    setFormData(data)
  }

  const canProceed = () => {
    return (
      formData.password.validateStatus === VALIDATE_STATUSES.SUCCESS &&
      formData.confirmPassword.validateStatus === VALIDATE_STATUSES.SUCCESS
    )
  }

  const submit = () => {
    validateFormFields()
    if (canProceed()) {
      dispatch(actions.restorePassword(token, formData.password.value, formData.confirmPassword.value))
    }
  }

  const handlePasswordVisibility = (field: string) => () => {
    const newState = clone(passwordsTypeState)
    // @ts-ignore
    newState[field] = !newState[field]
    setPasswordsState(newState)
  }

  const getPasswordInputType = (field: string) => {
    // @ts-ignore
    return passwordsTypeState[field] ? 'text' : 'password'
  }

  return (
    <AuthLayout AuthPageLadyImageMaxWidth={"400px"}>
      <div className="auth-form">
        <h1>{t('user.change-password.change_your_password')}</h1>
        <Form layout="vertical" id="myForm" hideRequiredMark onFinish={submit} autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t('user.change-password.password')}
                name="password"
                validateStatus={formData.password.validateStatus}
                validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                help={formData.password.errorMsg || tips}
              >
                <QInput
                  value={formData.password.value}
                  onChange={(e: any) => onPasswordChange('password', e.target.value)}
                  type={getPasswordInputType('password')}
                  placeholder={t('user.change-password.write_your_new_password')}
                  size="large"
                  style={{ paddingRight: i18n.dir() === 'rtl' ? '50px' : 'inherit' }}
                />
              </Form.Item>
              <QButtonEye onClick={handlePasswordVisibility('password')} isVisible={passwordsTypeState.password} />
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('user.change-password.confirm_password')}
                name="confirmPassword"
                validateStatus={formData.confirmPassword.validateStatus}
                validateTrigger={['onChange', 'onBlur']}
                help={formData.confirmPassword.errorMsg || null}
              >
                <QInput
                  value={formData.confirmPassword.value}
                  onChange={(e: any) => onPasswordChange('confirmPassword', e.target.value)}
                  type={getPasswordInputType('confirmPassword')}
                  placeholder={t('user.change-password.repeat_the_new_password')}
                  size="large"
                  style={{ paddingRight: i18n.dir() === 'rtl' ? '50px' : 'inherit' }}
                />
              </Form.Item>
              <QButtonEye
                onClick={handlePasswordVisibility('confirmPassword')}
                isVisible={passwordsTypeState.confirmPassword}
              />
            </Col>
          </Row>
          <QButton className="text-capitalize mt-16" type="primary" block htmlType="submit">
            {t('user.change-password.change_your_password')}
          </QButton>
        </Form>
      </div>
      <div className="auth-section-footer">
        <p>
          {t('common.Back_to', { ns: 'translationCommon' })} <Link to={PATHS.SIGN_IN}>{t('user.login.sign_in')}</Link>
        </p>
      </div>
    </AuthLayout>
  )
}
