import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import * as signInActions from "../signIn/signIn.actions";
import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { ISignUpResponse } from './signUp.types'
import * as CONSTANTS from './signUp.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { FormValues, SocialFormValues } from 'src/modules/registration/signUp/containers/SignUp.container'
import { LOCAL_STORAGE_KEYS, PATHS } from '../../../constants'
import history from '../../../utils/history'

export const resetState = (): IAction => ({ type: CONSTANTS.RESET_STATE })

export const signUpRequest = (): IAction => ({
  type: CONSTANTS.SIGN_UP_REQUEST,
})

export const signUpSuccess = (): IAction => ({
  type: CONSTANTS.SIGN_UP_SUCCESS,
})

export const signUpFailure = (error: string): IAction => ({
  type: CONSTANTS.SIGN_UP_FAILURE,
  error,
})

export const signUp = (
  data: FormValues,
  token: any,
  specialId: any,
  briefId: any
): ThunkAction<Promise<AxiosResponse<ISignUpResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignUpResponse>> => {
    try {
      dispatch(signUpRequest())

      let payload
      if (token && specialId)
        payload = {
          ...data,
          inviteToken: token,
          partnerData: specialId,
          briefId
        }
      else payload = { ...data }

      const response = await requestHttp.post<ISignUpResponse>(urls.getSignUpUrl(), payload)

      if (response.status === 201) {

        await localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);

        await sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
        dispatch(signUpSuccess())

        history.push(`${PATHS.REGISTER_SUCCESS}?id=${response.data?.data?.content?.id}&phone=${response.data?.data?.content?.phone}`)
      } else {
        dispatch(signUpFailure(getResponseErrorMessage(null)))
      }

      return response
    } catch (error) {
      dispatch(signUpFailure(getResponseErrorMessage(error)))

      return error
    }
  }

export const generateOTP = async (payload: any) => {
  try {
    const response = await requestHttp.post(urls.getGenerateOTPUrl(), payload)
    return response
  } catch (error) {
    return getResponseErrorMessage(error)
  }
}

export const verifyOTP = async (payload: any) => {
  try {
    const response = await requestHttp.post(urls.getVerifyOTPUrl(), payload)
    if (response.status === 200) {
      history.push(`/login`);
    }
    return response
  } catch (error) {
    return getResponseErrorMessage(error)
  }
}

export const socialSignUp = (
  data: SocialFormValues,
  token: any,
  specialId: any,
  briefId: any
): ThunkAction<Promise<AxiosResponse<ISignUpResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignUpResponse>> => {
    try {
      dispatch(signUpRequest())

      let payload
      if (token && specialId)
        payload = {
          ...data,
          inviteToken: token,
          partnerData: specialId,
          briefId
        }
      else payload = { ...data }

      const response = await requestHttp.post(urls.getSocialSignUpUrl(), payload)

      if (response.status === 201) {

        await localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ROLE);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_TOKEN);
        await localStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_PARTNER_LOGO);

        await sessionStorage.removeItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID)
        dispatch(signUpSuccess())

        if (response?.data?.data?.content?.partiallyRegistered) {
          history.push(`/signup/client/${response?.data?.data?.content?.socialToken}/${briefId}/${response?.data?.data?.content?.socialType}`)
        } else if (response?.data?.data?.content?.fullyRegistered) {
          if (!data.password && !data.email) history.push('/login')
          else await dispatch(
            signInActions.socialSignIn(
              data.socialToken,
              data.socialType,
              token,
              specialId,
              briefId,
              null
            )
          );
        }
      } else {
        if (!data.password && !data.email) history.push('/login')
        dispatch(signUpFailure(getResponseErrorMessage(null)))
      }

      return response
    } catch (error) {
      dispatch(signUpFailure(getResponseErrorMessage(error)))

      return error
    }
  }

export const quickSignUp = (email: string): ThunkAction<Promise<AxiosResponse<ISignUpResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<ISignUpResponse>> => {
  try {
    dispatch(signUpRequest())

    const response = await requestHttp.post<ISignUpResponse>(urls.getQuickSignUpUrl(), { email })

    if (response.status === 201) {
      dispatch(signUpSuccess())
      return response
    } else {
      dispatch(signUpFailure(getResponseErrorMessage(null)))
    }

    return response
  } catch (error) {
    dispatch(signUpFailure(getResponseErrorMessage(error)))

    return error
  }
}