import { GUARD_META, LOCAL_STORAGE_KEYS, PATHS } from '../../constants'
import getIsLoggedIn from '../../helpers/getIsLoggedIn'

const requireLogin = async (to: any, from: any, next: any) => {
  const isAuthenticated = await getIsLoggedIn()

  if (
    (to.match.path == '/briefs/create' || to.match.path == '/briefs/edit/:id' || to.match.path == '/briefs/v2/edit/:id' || to.match.path == '/briefs/v3/edit/:id') &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) != 'PARTNER'
  ) {
    if (
      (isAuthenticated && localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) == null) ||
      (!isAuthenticated && localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) != null) ||
      (isAuthenticated && localStorage.getItem(LOCAL_STORAGE_KEYS.INVITER_SPECIAL_ID) != null)
    ) {
      next()
    } else {
      next.redirect(PATHS.SIGN_IN)
    }
  }

  if (
    to.match.path === '/p/:id' &&
    (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) == 'CLIENT' ||
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) == 'PARTNER') &&
    isAuthenticated
  ) {
    next()
  }

  if (to.match.path === '/deployment-details' && localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ROLE) == 'CLIENT' && isAuthenticated) {
    next()
  }

  if (to.meta[GUARD_META.AUTH_ONLY] && !isAuthenticated) {
    if (
      window.location.host.includes('localhost') ||
      window.location.host.includes('saas-dev') ||
      window.location.host.includes('live') ||
      window.location.host.includes('prod') ||
      window.location.host.includes('srmg')
    ) {
      next.redirect(PATHS.HOME_PAGE)
    } else {
      next.redirect(PATHS.SIGN_IN)
    }
  }
  if (!to.meta[GUARD_META.AUTH_ONLY] && isAuthenticated) {
    next.redirect(PATHS.ROOT)
  }

  next()
}

export default requireLogin
