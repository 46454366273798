import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useReduxDispatch } from '../../../../helpers'
import * as actions from '../activateAccount.actions'

const ActivateAccount = () => {
  const { token } : any = useParams()
  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const briefId = urlParams.has('resumeBriefId') ? urlParams.get('resumeBriefId') : "";

  const dispatch = useReduxDispatch()

  const activateAccount = () => {
    // console.log(token,briefId)
    dispatch(actions.activateAccount(token,briefId))
  }

  useEffect(() => {
    activateAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default ActivateAccount
