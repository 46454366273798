import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import IAction from 'src/interfaces/IAction'
import { requestHttp, urls } from 'src/api'
import { IRestorePasswordResponse } from './restorePassword.types'
import * as CONSTANTS from './restorePassword.constants'
import { getResponseErrorMessage } from 'src/helpers'
import { PATHS } from '../../../constants'
import history from '../../../utils/history'

export const restorePasswordRequest = (): IAction => ({
  type: CONSTANTS.RESTORE_PASSWORD_REQUEST,
})

export const restorePasswordSuccess = (): IAction => ({
  type: CONSTANTS.RESTORE_PASSWORD_SUCCESS,
})

export const restorePasswordFailure = (error: string): IAction => ({
  type: CONSTANTS.RESTORE_PASSWORD_FAILURE,
  error,
})

export const restorePassword = (
  token: string,
  password: string,
  confirmPassword: string
): ThunkAction<Promise<AxiosResponse<IRestorePasswordResponse>>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<AxiosResponse<IRestorePasswordResponse>> => {
  try {
    dispatch(restorePasswordRequest())
    const response = await requestHttp.post<IRestorePasswordResponse>(urls.getChangePasswordUrl(), {
      token,
      password,
      confirmPassword,
    })
    dispatch(restorePasswordSuccess())

    history.push(PATHS.SIGN_IN)
    return response
  } catch (error: any) {
    dispatch(restorePasswordFailure(getResponseErrorMessage(error)))

    return error
  }
}
